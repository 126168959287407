import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` recently surpassed `}<strong parentName="p">{`50 Million messages`}</strong>{` processed.`}</p>
    <p>{`We thank all our customers for the continued support — we greatly appreciate it!`}</p>
    <p>{`In thanking our customers, it got us thinking about how often bot users thank the bots as well.`}</p>
    <p>{`The beauty of conversational interfaces is not only do people say what they want from the bot, they also say what they think about the bot.`}</p>
    <p>{`While we started out looking at the positive feedback, in fairness, we also considered the negative as it provides an opportunity to improve the bot too.`}</p>
    <h2>{`Bots… How do I Love Thee…`}</h2>
    <p>{`Have you told your bot lately that you love it?`}</p>
    <p>{`About 12% of Facebook bots on our platform and 18% of Slack bots received an “I love you” type of message from users.`}</p>
    <p>{`In fairness, 11% of Facebook bots and 12% of Slack bots also received an “I hate you” type of message. In many cases the bots received both types of messages — a bit of a “Love / Hate” relationship. In fact, 10% of Facebook and 7% of the Slack bots have received both an “I love you” and an “I hate you” message.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/10/07154342/0_VJKepNFbrWsbhoB3.png)
    </figure>
If you expand the types of messages beyond “I love you” and “I hate you” to
include similar positive and negative feedback — like “thank you” on the
positive side, and “you suck” or “f\* you” on the negative side, the difference
between positive and negative becomes more pronounced. For example, 34% of
Facebook bots received a positive compliment (variations of I love you and
thanks / thank you) vs. 16% received a negative message. For Slack, the
difference is even greater — 41% received a positive compliment, and 16%
received a negative one.
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/10/07154341/0_9nZHskWMzs-Xpg0a.png",
          "alt": null
        }}></img></figure>{`Oh What a Feeling… for Bots!`}</h2>
    <p>{`Related to these positive and negative messages is User Sentiment.`}</p>
    <p>{`User sentiment provides an indication of the users’ feelings when interacting with the bot.`}</p>
    <p>{`Looking across all the Slack and Facebook bots on our platform, the average sentiment per bot overall is positive. Users generally like interacting with the bots.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/10/07154341/0_a1kPvGYrqtvG0erR.png",
          "alt": null
        }}></img></figure>{`Taking Action`}</h2>
    <p>{`To take action on this data, you need to know what led to the positive or negative messages and sentiment.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` provides tools for this.`}</p>
    <p>{`With our Conversational Analytics, you can navigate the flow of messages through your bot to see what led to the message sent in.`}</p>
    <p>{`For example, in the image below, we can see when users enter “stop” into our Slack trivia game, what was happening in the bot beforehand, and what happened after, and we can continue to navigate the flow in both directions.`}</p>
    <p>{`This is useful for identifying where the bot is breaking down — if you look at the negative messages, or the “I don’t know,” or other error messages, you can see what users entered beforehand that led to that message. With this information, you can improve your bot to better handle the messages users send in.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/10/07154340/0_iZI7ZApSqhYBgHWx.png)
    </figure>
While following the flow is incredibly useful in identifying where the bot is
breaking down, it’s also helpful to look at the full transcripts as it may not
be just the preceding messages that led to the negative user response, but the
conversation itself.
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` provides the full transcripts of chat sessions so you can understand the context. We also have search functionality to search all transcripts for a particular message. From the transcripts, you can jump back into the message funnel to navigate the flow of messages.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/10/07154339/0_XikX8jRvjhcZSE70.png)
    </figure>
In addition to the transcripts, and message funnel,
[Dashbot](http://www.dashbot.io/) shows the top messages in to, and out from,
the bot. This is an additional way to understand how users interact with your
bot, as well as a jumping off point into the funnel. The top messages can be
filtered based on content type too — images, stickers, buttons, etc as seen in
the image below:
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2016/10/07154338/0_xxnGDylxgdPgWoUg.png",
          "alt": null
        }}></img></figure>{`Tips`}</h2>
    <p>{`It’s important to look at the messages users are sending in to your bot to make sure you’re handling them appropriately. As mentioned, users will tell you what they want from your bot as well as what they think of your bot — sometimes it’s not very pleasant. This provides an opportunity to improve your bot though to increase overall user engagement.`}</p>
    <h3>{`Fix What is Broken`}</h3>
    <p>{`Use the tools `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` provides to examine how users are interacting with your bot and identify the areas where the bot is breaking down and causing users to get upset or abandon the bot. Armed with this information, you can improve the bot experience.`}</p>
    <h3>{`Add Support for Unhandled Messages`}</h3>
    <p>{`Related to the above, look at the messages users are sending in to your bot, especially the rich media messages. Is your bot set up to handle these messages?`}</p>
    <p>{`We found customers who added support for rich media messages, were able to increase user engagement overall.`}</p>
    <h3>{`Create a Personality for Your Bot`}</h3>
    <p>{`Bots are conversational — like any conversation, it should be engaging. This is a perfect opportunity to create a personality for your bot — experiment with different messaging.`}</p>
    <p>{`Our customers found that by supporting different media types, including images, video, and audio, they were able to develop a personality for the bot, which increased overall user engagement.`}</p>
    <h2>{`Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables bot developers and enterprises to increase user acquisition, engagement, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we have bot specific processing and metrics like conversational analytics, sentiment analysis, and the full chat session transcripts.`}</p>
    <p>{`More importantly, we provide tools that make use of the data to improve acquisition and engagement, like live person take over of chat sessions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      